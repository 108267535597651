/**
 * Created by xinsw on 16/9/9.
 *
 * 用户中心的 service封装
 *
 */
import * as API from "../utils/http/api";


//http://120.76.222.96:9090/InodesServer/processImage/byNode?processNode=582aa1ec8c313f7733b160ee


/**
 * 登录
 * @param user  User  {username:string,password:string,captcha:string,rememberMe:boolean}
 * @returns {Promise.<*>}
 */
export async function login(user) {
  try {
    return await  API.post('login', user);
  } catch (error) {
    return error;
  }
}


/**
 *  登出
 * @returns {Promise.<*>}
 */
export async function logout() {
  try {
    return await  API.get('logout');
  } catch (error) {
    return error;
  }
}

/**
 *  向手机发送验证码
 * @returns {Promise.<*>}
 */
export async function sendMsgToPhone(phoneNum) {
  try {
    return await  API.get('validate/' + phoneNum);
  } catch (error) {
    return error;
  }
}

/**
 * 根据手机号和手机号收到的验证码修改用户绑定的手机号
 * @param param Object {mobile: mobile, validateCode: validateCode}
 * @returns {Promise.<*>}
 */
export async function updateMobile(param) {
  try {
    return await  API.post('user/updateMobile', param);
  } catch (error) {
    return error;
  }
}

/**
 * 根据手机号和手机号收到的验证码修改用户绑定的手机号
 * @param param Object
 * @returns {Promise.<*>}
 */

/**
 * 更新用户信息，只能是以下信息
 * @param userInfo Object {position: 职位, name: 姓名,email:邮箱}
 * @returns {Promise.<*>}
 */
export async function updateUserinfo(userInfo) {
  try {
    return await  API.post('user/update', userInfo);
  } catch (error) {
    return error;
  }
}

/**
 * 修改密码
 * @param pwdData Object {oldPassword:'老密码',newPassword:'新密码'}
 * @returns {Promise.<*>}
 */
export async function updatePassword(pwdData) {
  try {
    return await  API.post('user/updatePassword', pwdData);
  } catch (error) {
    return error;
  }
}

/**
 * 修改用户的头像
 * @param userInfo Object
 * @returns {Promise.<*>}
 */
export async function updateHeadImage(userInfo) {
  try {
    return await  API.post('/user/updateImage', userInfo);
  } catch (error) {
    return error;
  }
}


/**
 * 验证身份做忘记密码
 * @param pwdData Object {oldPassword:'老密码',newPassword:'新密码'}
 * @returns {Promise.<*>}
 */
export async function checkForget(pwdData) {
  try {
    return await  API.post('forget', pwdData);
  } catch (error) {
    return error;
  }
}

/**
 * 忘记密码身份验证通过修改密码
 * @param submitPassword String
 * @returns {Promise.<*>}
 */
export async function forgetSumbitPwd(submitPassword) {
  try {
    return await  API.post('updatePassword', {submitPassword});
  } catch (error) {
    return error;
  }
}

/**
 * 更新默认设备
 */
export async function updateDefaultDevice(defaultDevice) {
  try {
    return await  API.post('user/updateDefaultDevice', {defaultDevice});
  } catch (error) {
    return error;
  }
}
