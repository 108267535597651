/**
 * 封装全局的自定义验证
 */

/**
 * 邮箱验证
 * @param rule
 * @param value
 * @param callback
 */
export const valiEmail = (rule, value, callback) => {
  if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
    callback(new Error('请正确的输入邮箱!'));
  } else {
    callback();
  }
};

/**
 * 手机号验证
 * @param rule
 * @param value
 * @param callback
 */
export const valiPhone = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入手机号码'));
  } else if (!/^1[3456789]\d{9}$/.test(value)) {
    callback(new Error('请正确的输入手机号码!'));
  } else {
    callback();
  }
};


/**
 * 手机验证码验证
 * @param rule
 * @param value
 * @param callback
 */
export const valiCheckCode = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入手机验证码'));
  } else if (isNaN(value) || (value + '').length > 6 || (value + '').length < 4) {
    callback(new Error('验证码必须是4-6位的数字值'));
  } else {
    callback();
  }
};

/**
 * 账号验证
 * @param rule
 * @param value
 * @param callback
 */
export const valiAccount = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入您的账号名'));
  } else if (!/^[a-zA-Z0-9-_-]{4,30}$/.test(value)) {
    callback(new Error('账号只能由4-30位数字和字母组成!'));
  } else {
    callback();
  }
};

/**
 * 密码验证
 * @param rule
 * @param value
 * @param callback
 */
export const valiPassword = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入您的密码'));
  }
  else if (value.length < 6) {
    callback(new Error('密码长度不能小于6!'));
  }
  else if (value.length > 30) {
    callback(new Error('密码长度不能大于30!'));
  }
  else if (!/^[a-zA-Z0-9]{5,30}$/.test(value)) {
    callback(new Error('密码以字母开头，只能包含字符、数字和下划线!'));
  } else {
    callback();
  }
};

/**
 *
 * 2-50位不能为空的验证
 * @param rule
 * @param value
 * @param callback
 * @returns {*}
 */
export const valiInput = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('不能为空'));
  }
  else {
    if (value.length < 2 || value.length > 50) {
      return callback(new Error('必须为长度为2-50的字符'));
    }
    else {
      callback();
    }
  }
};


/**
 * 选择框必须要选一项
 * @param rule
 * @param value
 * @param callback
 * @returns {*}
 */
export const valiSelect = (rule, value, callback) => {
  if (typeof value == 'undefined' || value == '') {
    return callback(new Error('不能为空'));
  }
  else {
    callback();
  }
};

/**
 * 不能是空并且一定是数字
 * @param rule
 * @param value
 * @param callback
 * @returns {*}
 */
export const valiNoEmptyNumber = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('不能为空'));
  }
  else if(!/[0-9]+/.test(value)) {
    return callback(new Error('必须为数字'));
  } else {
    callback()
  }
}
