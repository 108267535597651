<style lang="less" scoped>

  @import "../../assets/css/variables";

  .login-page {
    margin: 0 !important;
    padding: 0 !important;
    height: 100%;
  }

  .content {
    position: absolute;
    box-sizing: border-box;
    background-color: #d2d6d9;
    top: @header-height;
    bottom: 140px;
    &.login-bg {
      padding: 0;
      margin: 0;
      width: 100%;
      background: no-repeat center;
      //background-image: url("~/static/welcome.png");
      background-size: cover;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .login-container {
      margin-right: 35px;
      width: 286px;
      height: 310px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      overflow: hidden;
    }
    .user-form {
      padding: 30px;
      .captcha-input {
        width: 100%;
      }
      .captcha-wrapper {
        position: absolute;
        right: 3px;
        top: 0;
        width: 28%;
        height: 36px;
        line-height: 36px;
        text-align: right;
      }
      .captcha {
        width: 100%;
        vertical-align: middle;
      }
      .login-button {
        button {
          width: 100%;
        }
      }

    }
  }

  .contact-wrapper {
    position: absolute;
    bottom: 55px;
    width: 100%;
    .login-link {
      color: @baseColor;
      line-height: 19px;
      padding: 14px 0;
      display: block;
    }
  }

  .bottom {
    position: absolute;
    bottom: 5px;
    width: 100%;
  }

</style>

<template>
  <div class="login-page" v-on:keyup.13="doLogin">
    <nomenu-header></nomenu-header>
    <div class="content login-bg" :style="{'background-image':'url('+loginBg+')'}">
      <div class="login-container">
        <el-form :model="user" :rules="rules" auto-complete="on" ref="userForm" class="user-form">
          <el-form-item prop="username">
            <el-input v-model="user.username" auto-complete="username" placeholder="请输入您的用户名"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" v-model="user.password" auto-complete="on" placeholder="请输入您的密码"></el-input>
          </el-form-item>
          <el-form-item prop="captcha" class="m-b">
            <el-input v-model="user.captcha" class="captcha-input"
                      :maxlength="6" placeholder="请输入验证码"></el-input>
            <div class="captcha-wrapper pull-right">
              <img :src="captchaImage" class="captcha" @click="refreshCaptchaImage" height="26"/>
            </div>
          </el-form-item>
          <el-form-item class="m-b">
            <el-checkbox v-model="user.rememberMe">记住密码</el-checkbox>
            <router-link style="color:#1F3D2F" class="pull-right" to="/forgetpwd">忘记密码?</router-link>
          </el-form-item>
          <el-form-item class="login-button">
            <el-button type="primary" size="large" @click="doLogin">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="contact-wrapper">
      <el-row>
        <el-col :offset="4" :span="4">
          <el-col :span="8">
            <img height="60" :src="QqImg">
          </el-col>
          <el-col :span="16">
            <a target="_blank" class="login-link" href="http://www.ningborunner.com">
              官方网站:<br>
              www.ningborunner.com
            </a>
          </el-col>
        </el-col>
        <el-col :offset="2" :span="4">
          <el-col :span="8">
            <img height="60" :src="BusinessImg">
          </el-col>
          <el-col :span="16">
            <a href="tel://0578-6961784" class="login-link">
              商务洽谈:<br>
              0574-65848888
            </a>
          </el-col>
        </el-col>
        <el-col :offset="2" :span="4">
          <el-col :span="8">
            <img height="60" :src="TelImg">
          </el-col>
          <el-col :span="16">
            <a href="tel://0578-6961411" class="login-link">
              技术支持:<br>
              0574-65848520
            </a>
          </el-col>
        </el-col>
      </el-row>
      <!--<div class="col-lg-offset-2 col-lg-8">
        <div class="row">
          <div class="col-lg-4 text-left">
            <div class="row">
              <div class="col-lg-4"><img height="60" src="../../assets/images/qq.png">　</div>
              <a ng-class="[styleObj.outerHref,styleObj.contcatLine,'col-lg-8']" target="_blank"
                 href="http://www.chinasanchen.com">
                官方网站:<br>
                www.chinasanchen.com
              </a>
            </div>
          </div>
          <div class="col-lg-4 text-left">
            <div class="row">
              <div class="col-lg-4"><img height="60" src="../../assets/images/business.png">　</div>
              <a ng-class="[styleObj.outerHref,styleObj.contcatLine,'col-lg-8']"
                 href="tel://0578-6961784">
                商务洽谈:<br>
                0578-6961784
              </a>
            </div>
          </div>
          <div class="col-lg-4 text-left">
            <div class="row">
              <div class="col-lg-4"><img height="60" src="../../assets/images/tel.png">　</div>
              <a ng-class="[styleObj.outerHref,styleObj.contcatLine,'col-lg-8']"
                 href="tel://0578-6961411">
                技术支持:<br>
                0578-6961411
              </a>
            </div>
          </div>
        </div>
      </div>-->
    </div>
    <justcr-footer class="bottom"></justcr-footer>
  </div>
</template>

<script>

  import {Notification} from 'element-ui';
  //import captchaImage from '../../components/captchaimage';
  //webpack 会将此转为base64 然后就可以用了，src一个静态本地想对路径图片的时候会被webpack url-loader拦截
  import defaultImg from '../../assets/images/defaultImg.jpg';
  import cookie from '../../utils/http/cookie';
  import {API_ROOT, IMAGE_PATH}from '../../config';
  import {login} from '../../services/ucenter';
  import justcrFooter from "../common/footer-justcr.vue";
  import noMenuHeader from "../common/header-nomenu.vue";
  import {valiAccount, valiPassword} from '../../utils/validator/element';
  import QqImg from '../../assets/images/qq.png';
  import TelImg from '../../assets/images/tel.png';
  import BusinessImg from '../../assets/images/business.png';

  //Vue.component('search-box', SearchBox)
  export default {
    beforeRouteEnter(to, from, next) {
      next();
    },
    components: {
      'justcr-footer': justcrFooter,
      'nomenu-header': noMenuHeader
    },
    data() {

      return {
        QqImg,
        TelImg,
        BusinessImg,
        captchaImage: API_ROOT + "/captchaImage?" + new Date().getTime(),
        loginBg: IMAGE_PATH + 'welcome.png',
        user: {
          username: '',
          password: '',
          captcha: '',
          rememberMe: true
        },
        rules: {
          username: [
            {validator: valiAccount, trigger: 'blur'}
          ],
          password: [
            {validator: valiPassword, trigger: 'blur'}
          ],
          captcha: [
            {required: true, message: '请输入验证码', trigger: 'blur'},
            {min: 4, max: 6, message: '验证码输入错误', trigger: 'blur'}
          ]
        }

      }
    },
    created(){
      try {
        let user = JSON.parse(cookie("userInfo"));
        if (user.username) {
          this.user.username = user.username;
        }
      } catch (e) {

      }
      //规避App中
    },
    methods: {
      /**
       * [刷新验证码]
       * @return {[type]} [description]
       */
      refreshCaptchaImage: function () {
        this.captchaImage = API_ROOT + "/captchaImage?" + new Date().getTime();
      },
      loginNotice() {
        this.$notify({
          title: '警告',
          message: '您没有登陆权限',
          type: 'warning',
          duration: 0
        });
      },
      /**
       * [提交登陆]
       * @param  {[event]} _e [event]
       * @return {[null]}   [null]
       */
      doLogin: async function (_e) {
        //  this.user.captcha = captchaComp.code;
        const responseData = await login(this.user);
        //console.log(responseData);
        if (responseData.errorCode === 0) {
          const user = responseData && responseData.data && responseData.data.user;
          const permissions = user.permissions;
          // 登陆权限判断
          // if (permissions.indexOf('Menu_PCLogin') === -1) {
          //   this.loginNotice();
          //   return;
          // }
          cookie('userInfo', JSON.stringify(user));
          this.$store.dispatch('refreshUserInfo', user);
          
          // if (user.role === 'bigScrean') {
          //   window.router.replace({path: '/dvcStat'});
          // } else {
          //   window.router.replace({path: '/index'});
          // }
          // window.router.replace({path: '/dvcStat'});
          window.router.replace({path: '/device/list'});

        } else {
          this.user.captcha = '';
          this.refreshCaptchaImage();
        }
      }
    }
  }

</script>
