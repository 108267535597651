<!--
  登录界面的的footer  只有简单的copyright信息
-->
<style lang="less" scoped>
  @import "../../assets/css/variables";

  .copyright {
    text-align: center;
    color: #888c8e;
    line-height: 50px;
    font-size: 14px;
  }


</style>

<template>
  <div class="footer">
    <div class="copyright">
      CopyRight © 2013 浙江威霖 浙ICP备13002770号-1
    </div>
  </div>
</template>

<script>

  export default {
    mounted() {
      //this.updateFooterStatus(_index);
    },
    data() {
      return {}

    },
    methods: {}
  }

</script>
