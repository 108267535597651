<!--
  登录等页面的header  只有logo
-->
<style lang="less" scoped>
  @import "../../assets/css/variables";

  .header {
    height: @header-height;
    color: #fff;
    line-height: @header-height;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    position: relative;
    background-color: #324157;
    padding: 0 32px 0 35px;
    .help {
      color: #fff;
      margin-right: 10px;
      cursor: pointer;
      > i {
        position: relative;
        top: 2px;
        font-size: 20px;
      }
      .el-dropdown {
        color: #fff;
        font-size: @font-size-base;
      }
    }

  }

  .welcome {
    font-size: 20px;
    margin-top: -5px;
    margin-left: 35px;
  }

</style>

<style lang="less">
  .el-dropdown-menu-sm {
    .qrcode {
      position: relative;
      background-color: #fff;
      width: 140px;
      z-index: 2000;
      padding: 5px 10px;
      font-size: 12px;
      text-align: center;
    }

    .qrcode img {
      width: 120px;
      margin-bottom: 5px;
    }
  }
</style>
<template keep-live>
  <header class="header">
    <logo class="pull-left" alt="威霖集团"></logo>
    <span class="welcome"></span>
    <div class="pull-right help">
      <!-- <el-dropdown>
          <span class="el-dropdown-link">
            &nbsp;应用下载
          </span>
        <el-dropdown-menu class="el-dropdown-menu-sm" slot="dropdown">
          <div class="qrcode  animated fadeInRight">
            <img :src="QRCode"/>
            <div>扫描下载三辰直流电源远程监控APP</div>
          </div>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
    <!-- <a target="_blank" href="http://www.chinasanchen.com/products?type=1" class="pull-right help">
      <i class="ion ion-ios-help-outline"></i>&nbsp;产品中心
    </a> -->
    <a href="http://runner-corp.com/" target="_blank" class="pull-right help">
      <i class="ion ion-ios-help-outline"></i>&nbsp;关于我们
    </a>

  </header>
</template>

<script>

  import Logo from './logo.vue';
  import {Notification} from "element-ui";
  import {logout} from "../../services/ucenter";
  import QRCode from '../../assets/images/qrcode.png';
  export default {
    data() {
      return {QRCode};
    },
    components: {
      Logo
    },
    methods: {},
    created(){
    },
    watch: {}
  }

</script>
