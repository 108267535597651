<style lang="less" scoped>
  @import "../../assets/css/variables";

  .header-logo {
    height: 30px;
    display: inline-block;
    > img {
      //width: 100px;
      max-height: 22px;
      vertical-align: middle;
    }
  }

  .header-logo-large {
    height: 36px;
    display: inline-block;
    > img {
      margin-top: -5px;
      max-height: 28px;
      width:140px;
      vertical-align: middle;
    }
  }
</style>

<template>
  <div :class="className">
    <img :alt="alt" :src="Logo"/>
  </div>
</template>

<script>
  import Logo from '../../assets/images/logo.png';

  export default {
    props: ['alt', 'size'],
    mounted() {
      //this.updateFooterStatus(_index);
    },
    data() {
      return {
        className: 'header-logo',
        Logo
      }

    },
    watch: {
      size: function (newVal, oldVal) {
        if (newVal) {
          this.className = newVal === 'large' ? 'header-logo-large' : 'header-logo';
        }
      }
    },
    created(){
      this.className = this.size === 'large' ? 'header-logo-large' : 'header-logo';
    }
  }

</script>
